<template>
  <v-card>
    <v-toolbar color="transparent" flat height="55">
      <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">{{ isCurrentDashboard ? 'Editar información del' : 'Crear nuevo' }} Dashboard</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-row align="center" justify="center" class="pr-1">
          <v-btn icon :ripple="false" small @click.native="$emit('close')"><v-icon small>mdi-close</v-icon></v-btn>
        </v-row>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider />
    <v-card-text class="pa-0">
      <v-row no-gutters class="pa-0" v-if="loading">
        <v-col cols="3" class="px-0 py-4">
          <v-skeleton-loader transition="fade-transition" type="card-heading"></v-skeleton-loader>
          <v-skeleton-loader class="mt-5" transition="fade-transition" type="card-heading"></v-skeleton-loader>
        </v-col>
        <v-col cols="9" class="px-0 py-4">
          <v-skeleton-loader class="ml-n3 mb-4" transition="fade-transition" type="card-heading"></v-skeleton-loader>
          <v-skeleton-loader class="mt-2 mb-4" transition="fade-transition" type="image" style="width:420px; height:180px"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-list class="v-list-form-extended py-6 transparent" v-else>
        <v-list-item class="py-1" inactive :ripple="false">
          <v-list-item-title>
            Nombre
            <v-tooltip color="bgSearch" right max-width="280px">
              <template v-slot:activator="{on}">
                <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
              </template>
              <span>Escribe un nombre para que puedas identificar fácilmente tu dashboard.</span>
            </v-tooltip>
          </v-list-item-title>
          <v-list-item-subtitle class="pl-5">
            <v-text-field v-model="$v.dashboard.name.$model" :error="$v.dashboard.name.$error" outlined required single-line dense maxlength="56" placeholder="Escribe un nombre" hide-details autocomplete="off" />
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="pt-2" inactive :ripple="false">
          <v-list-item-title>Descripción</v-list-item-title>
          <v-list-item-subtitle class="pl-5">
            <v-textarea class="pb-0" v-model="$v.dashboard.description.$model" :error="$v.dashboard.description.$error" auto-grow solo flat outlined hide-details placeholder="Escribe una descripción corta para tu dashboard" />
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider />
    <v-card-actions class="pa-4">
      <v-spacer />
      <v-btn @click.native="$emit('close')" outlined color="secondary">Cancelar</v-btn>
      <v-btn @click="addInfoDashboard()" color="primary">Guardar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import cloneDeep from 'lodash/cloneDeep'
export default {
  props: {
    isCurrentDashboard: {
      type: String,
      default: () => { return null }
    }
  },
  data: () => ({
    dashboard: {
      name: '',
      description: '',
      layout: []
    },
    loading: false
  }),
  created () {
    if (this.isCurrentDashboard !== null) {
      this.loading = true
       this.$store.dispatch('dashboards/RETRIEVE', {
        resource: 'metrics/dashboards',
        id: this.isCurrentDashboard
      })
      .then((response) => {
        this.loading = false
        const data = cloneDeep(response.data)
        this.dashboard = data
      })
    }
  },
  methods: {
    addInfoDashboard () {
      this.$v.dashboard.$touch()
      if (this.$v.dashboard.$invalid) {
        return false
      }
      if (this.isCurrentDashboard !== null) {
        this.$store.dispatch('dashboards/UPDATE', {
        resource: 'metrics/dashboards',
        id: this.isCurrentDashboard,
        payload: this.dashboard
      })
      .then((response) => {
        this.$dialog.message.info('Dashboard guardado correctamente')
        this.$store.dispatch('dashboards/LIST', {
          resource: 'metrics/dashboards'
        })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      } else {
        this.dashboard.layout = []
        this.$store.dispatch('dashboards/CREATE', {
          resource: 'metrics/dashboards',
          payload: this.dashboard
        })
        .then((response) => {
          this.$dialog.message.info('Has creado un nuevo dashboard')
          this.$router.push({
            name: 'DashboardUpdate',
            params: {
              id: response.data.id
            }
          })
        })
        .catch(() => {
          this.$dialog.message.error('No se pudo crear el dashboard')
        })
      }
      this.$emit('close')
    }
  },
  validations: {
    dashboard: {
      name: {
        required,
        maxLength: maxLength(56)
      },
      description: {
        maxLength: maxLength(256)
      }
    }
  }
}
</script>
<style scoped>
::v-deep .theme--light.v-skeleton-loader .v-skeleton-loader__card-heading {
  background: transparent !important;
}
</style>